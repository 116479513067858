import React from "react";
import { Box } from "@mui/material";

import NavBar from "./components/NavBar";
import PageContent from "./components/PageContent";
import Footer from "./components/Footer";
import { INavigationLink } from "../../types/nav";
import WhatsAppButton from "./components/WhatsAppButton";

interface NavigationTemplateProps extends React.PropsWithChildren {
  links: INavigationLink[];
}

export default function NavigationTemplate({ links, children }: NavigationTemplateProps) {
  return (
    <Box component="main" className="min-h-screen">
      <NavBar links={links} />
      <PageContent component="article" children={children} />
      <Footer links={links} />
      <WhatsAppButton />
    </Box>
  );
}
