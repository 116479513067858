/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  ThemeProvider,
  Typography
} from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";

import colors from "../../../themes/colors";
import darkTheme from "../../../themes/darkTheme";
import GridContainer from "../../../components/GridContainer";
import LinkButton from "../../../components/LinkButton";
import Translate from "../../../components/Translate";
import socialMedias from "../../../payload/social-media";
import { INavigationLink } from "../../../types/nav";

interface FooterProps {
  links: INavigationLink[];
}

export default function Footer({ links }: FooterProps) {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box bgcolor={theme => theme.palette.background.default} component="footer">
        <GridContainer>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} justifyContent="center">
              <Container sx={theme => ({ [theme.breakpoints.down("md")]: { p: 0, m: 0 } })}>
                <Box className="mb-16">
                  <img className="w-12" src="/logo192.png" alt="bizpik-logo" />

                  <Stack className="my-10 justify-between flex-col sm:flex-row">
                    <List
                      disablePadding
                      sx={{
                        "& .MuiListItem-root:not(:last-of-type)": {
                          paddingBottom: theme => theme.spacing(2)
                        },

                        "& .MuiListItem-root .MuiListItemIcon-root": {
                          minWidth: 0,
                          marginRight: theme => theme.spacing(2)
                        }
                      }}
                    >
                      {links.map(link =>
                        link.hiddenOnDesktop ? null : (
                          <ListItem key={link.anchor} disablePadding>
                            <LinkButton
                              children={<Translate customKey={link.label} />}
                              anchor={
                                !link.anchor || link.anchor.startsWith("#")
                                  ? link.anchor
                                  : `#${link.anchor}`
                              }
                              href={link.path}
                            />
                          </ListItem>
                        )
                      )}
                    </List>

                    <Box>
                      <Button
                        href="https://register.bizpik.com.br/company?plan=start"
                        sx={{
                          backgroundColor: colors.violet,
                          textTransform: "none",
                          fontSize: 14,
                          lineHeight: "22px",
                          height: "auto",
                          ":hover": {
                            backgroundColor: colors.violetDarker
                          }
                        }}
                        className="py-1.5 mb-6 mt-10 sm:mt-0"
                        variant="contained"
                        children={<Translate i18nKey="label:test" />}
                      />

                      <LinkButton
                        enableUnderscore
                        underscoreColor={theme => theme.palette.text.secondary}
                        className="mb-8"
                        children={<Translate i18nKey="nav:policy" />}
                        href="policy"
                      />

                      <Typography
                        className="font-medium"
                        color={theme => theme.palette.text.primary}
                        fontSize={18}
                      >
                        BizPik Soluções Avançadas em Tecnologia
                      </Typography>

                      <Typography
                        className="font-normal"
                        color={theme => theme.palette.text.secondary}
                        fontSize={16}
                      >
                        Rua Cardoso de Almeida, 634 - Conj. 11 <br />
                        Perdizes – São Paulo – Brasil – CEP 05013-000
                        <br />
                        Tel.: (11) 97879-1500
                        <br />
                        <a href="mailto:contato@bizpik.com.br">E-mail: contato@bizpik.com.br</a>
                        <br />
                        CNPJ: 51.317.582/0001-75
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Divider />

                <Stack className="justify-center text-center items-center mt-8 flex-col sm:text-left sm:flex-row sm:items-start sm:justify-between">
                  <Typography
                    color={theme => theme.palette.text.primary}
                    className="font-normal"
                    fontSize={14}
                  >
                    <Translate
                      i18nKey="misc:copyright"
                      values={{ year: format(_.now(), "yyyy") }}
                    />
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={2.5}
                    className="items-center self-center mt-12 sm:self-end sm:mt-0"
                  >
                    {socialMedias.map(socialMedia => (
                      <LinkButton key={socialMedia.name} newTab href={socialMedia.address}>
                        <img
                          className="w-8 sm:w-6"
                          src={socialMedia.image}
                          alt={socialMedia.name}
                        />
                      </LinkButton>
                    ))}
                  </Stack>
                </Stack>
              </Container>
            </Grid>
          </Grid>
        </GridContainer>
      </Box>
    </ThemeProvider>
  );
}
